import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Container from "../components/container";

const KontaktDziekujemy = ({ data }) => {
  return (
    <Layout
      title="Dziękujemy za kontakt"
      headline=""
    >
      <SEO
        title="Kontakt Empireo"
        description="Kontakt Empireo"
      />
      <Container>
        <div
          style={{
            marginTop: 100
          }}
        >
          <h1
            style={{
              fontSize: 24,
              marginBottom: 20,
              marginTop: 100
            }}
          >
            Dziękujemy
            <br />
            za zainteresowanie systemem Empireo
          </h1>
        </div>
        <div>
          <p>
            Skontaktujemy się z Tobą tak szybko, jak to tylko możliwe. Chętnie odpowiemy na wszystkie Twoje pytania i opowiemy o systemie Empireo.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default KontaktDziekujemy;
